import React, { Component } from "react"
import { Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import './teamCard.css'

class TeamCard extends Component {
    render() {
        return (
            <Col className="member" xs="6" sm="4" md="3" xl="2">
                <ScrollAnimation animateIn="fadeInUp" offset={150} animateOnce={true}>
                    <img src={this.props.img} alt={this.props.name} style={{ width: '100%' }} />
                    <div className="member-info">
                        <div className="member-info-content">
                            <h4>{this.props.name}</h4>
                            <span>{this.props.funktion}</span>
                            <span>{this.props.funktion2}</span>
                        </div>
                    </div>
                </ScrollAnimation>
            </Col>
        )
    }
}

export default TeamCard



